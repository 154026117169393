import { NavLink } from "react-router-dom";
import "./menuItem.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const MenuItem = ({ showSubmenu, name, route, isParent, customClass }) => {

  const [keepshowSubmenu, setKeepshowSubmenu] = useState(false);
  const { t } = useTranslation("translation");

  return (
    <>
      <NavLink className="navbar-items__menu-list--link" to={route}>
        <div>
          <div className="parentItem-container">
            <div className="parentItem">
              <span className={customClass}>{t(name)}</span>
            </div>
          </div>
        </div>
      </NavLink>
          {((isParent && showSubmenu) || keepshowSubmenu) && (
            <div
              className="childrenItem"
              onMouseOver={() => setKeepshowSubmenu(true)}
              onMouseLeave={() => setKeepshowSubmenu(false)}
            >
              <ul className="childrenItem__list">
                <NavLink className="childrenItem__list--link" to="/services/1">
                  <span className={"childrenItem__list--text " + customClass}>
                    {t("StrategyTransformation")}
                  </span>
                </NavLink>
                <NavLink className="childrenItem__list--link" to="/services/2">
                  <span className={"childrenItem__list--text " + customClass}>
                    {t("BusinessSolutionsDeals")}
                  </span>
                </NavLink>
                <NavLink className="childrenItem__list--link" to="/services/3">
                  <span className={"childrenItem__list--text " + customClass}>
                    {t("BusinessExcellenceCapabilityBuilding")}
                  </span>
                </NavLink>
                <NavLink className="childrenItem__list--link" to="/services/4">
                  <span className={"childrenItem__list--text " + customClass}>
                    {t("SpecializedConsultingServices")}
                  </span>
                </NavLink>
              </ul>
            </div>
          )}
    </>
  );
};

export default MenuItem;
