import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


function Logo({logoHeight , logoWidth , backgroundColor , logoImg}) {
  const { t } = useTranslation("translation");

  return (
    <div className="logo">
      <Link to="/">
        <picture>
          <source srcSet={t("lang") === "عربي" ? "/assets/images/logo-en-light.png" : "/assets/images/logo-ar-light.png"} media="(min-width : 1024px)" />
          <source srcSet={t("lang") === "عربي" ? "/assets/images/logo-en-dark.png" : "/assets/images/logo-ar-dark.png"} media="(max-width : 1024px)" />
          <img
            className="logo-image"
            id="logo-image"
            width={logoWidth}
            height={logoHeight}
            style={{ backgroundColor: `${backgroundColor}` }}
            src={logoImg || t("lang") === "عربي" ? "/assets/images/logo-en-dark.png" : "/assets/images/logo-ar-dark.png"}
            alt="logo"
          />
        </picture>
      </Link>
    </div>
  );
}

export default Logo;
