import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Container from "./layouts/Container.jsx";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation("translation");

  return (
    <BrowserRouter>
      <div
        className="App"
        style={
          t("lang") === "عربي"
            ? { fontFamily: "Heebo " }
            : { fontFamily: "Tajawal" }
        }
        dir={t("lang") === "عربي" ? "ltr" : "rtl"}
      >
        <Container />
      </div>
    </BrowserRouter>
  );
}

export default App;
