import { useEffect, useState } from "react";
import "./navbar.scss";
import MenuItem from "../MenuItem";
import { useTranslation } from "react-i18next";
import Logo from "../Logo/index";
import LanguageIcon from "@mui/icons-material/Language";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const Navbar = ({style}) => {
  const { t } = useTranslation("translation");

  const [showXSvg, setShowXSvg] = useState(false);
  const location =  useLocation()
  
  useEffect(()=>{
    setShowXSvg(false);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  },[location.pathname])

  const displayDropDown = () => {
    setShowXSvg((prev) => !prev);
  };
  const [menuItemsArray, setMenuItemsArray] = useState([
    {
      name: "Home",
      route: "/",
      defaultClass: "navbar-item",
      mobileClass: "navbar-item-drop-down",
      isParent: false,
      showSubmenu: false,
    },
    {
      name: "About",
      route: "/about",
      defaultClass: "navbar-item",
      mobileClass: "navbar-item-drop-down",
      isParent: false,
      showSubmenu: false,
    },
    {
      name: "Services",
      route: "/services",
      defaultClass: "navbar-item",
      mobileClass: "navbar-item-drop-down",
      isParent: true,
      showSubmenu: false,
    },
    {
      name: "Industries",
      route: "/industries",
      defaultClass: "navbar-item",
      mobileClass: "navbar-item-drop-down",
      isParent: false,
      showSubmenu: false,
    },
    {
      name: "Insights",
      route: "/insights",
      defaultClass: "navbar-item",
      mobileClass: "navbar-item-drop-down",
      isParent: false,
      showSubmenu: false,
    },
    {
      name: "Press",
      route: "/press",
      defaultClass: "navbar-item",
      mobileClass: "navbar-item-drop-down",
      isParent: false,
      showSubmenu: false,
    },
    {
      name: "Contact",
      route: "/contact",
      defaultClass: "navbar-item",
      mobileClass: "navbar-item-drop-down",
      isParent: false,
      showSubmenu: false,
    },
    {
      name: "career",
      route: "https://hunt.kayanhr.com/Navigator/CareerSite/z3Hf9O3l9foOXJ/",
      defaultClass: "navbar-item",
      mobileClass: "navbar-item-drop-down",
      isParent: false,
      showSubmenu: false,
    },
  ]);

  const handleChangeLang = (lang) => {
    localStorage.setItem("lang", lang === "عربي" ? "ar" : "en");
    i18next.changeLanguage(lang === "عربي" ? "ar" : "en");
  };

  const showDropDownMenuItem = (MenuItem) => {
    MenuItem.showSubmenu = true;
    setMenuItemsArray([...menuItemsArray]);
  };
  
  const hideDropDownMenuItem = (MenuItem) => {
    MenuItem.showSubmenu = false;
    setMenuItemsArray([...menuItemsArray]);
  };

  const HandleMobileItemMenu = (MenuItem) => {
    MenuItem.showSubmenu = !MenuItem.showSubmenu;
    setMenuItemsArray([...menuItemsArray]);
  };

  return (
    <>
      <nav style={style} className="navbar">

        <Logo />

        <div className="navbar-items">
          <ul className="navbar-items__menu">
            {menuItemsArray.map((item, index) => {
              return (
                <li
                  onMouseLeave={() => hideDropDownMenuItem(item)}
                  onMouseOver={() => showDropDownMenuItem(item)}
                  key={index}
                  className="navbar-items__menu-list"
                >
                  <MenuItem
                    showSubmenu={item.showSubmenu}
                    customClass={item.defaultClass}
                    name={item.name}
                    route={item.route}
                    isParent={item.isParent}
                  />
                </li>
              );
            })}
            <li className="navbar-items__menu-list">
              <div className="parentItem">
                <button
                  onClick={() => handleChangeLang(t("lang"))}
                  className="navbar-items__menu-list--btn"
                >
                  <span className="navbar-item">
                    <LanguageIcon
                      fontSize="small"
                      className="navbar-item--icon"
                    />
                    {t("lang")}
                  </span>
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div className="navbar-mobile" onClick={displayDropDown}>
          <svg
            className={`svg ${showXSvg ? "hidden" : "visible"}`}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100"
            height="100"
            viewBox="0 0 50 50"
          >
            <path d="M 5 9 L 5 11 L 45 11 L 45 9 L 5 9 z M 5 24 L 5 26 L 45 26 L 45 24 L 5 24 z M 5 39 L 5 41 L 45 41 L 45 39 L 5 39 z"></path>
          </svg>

          <svg
            className={`svg ${showXSvg ? "visible" : "hidden"}`}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100"
            height="100"
            viewBox="0 0 50 50"
          >
            <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
          </svg>
        </div>
      </nav>

      {showXSvg && (
        <div
          className={`navbar-items-mobile-dropdown ${
            showXSvg ? "visible-dropdown" : "hidden-dropdown"
          }`}
        >
          <ul className="navbar-items-mobile-dropdown__menu">
            {menuItemsArray.map((item, index) => {
              return (
                <li
                  key={index}
                  className="navbar-items-mobile-dropdown__menu-list"
                >
                  <MenuItem
                    showSubmenu={item.showSubmenu}
                    customClass={item.mobileClass}
                    name={item.name}
                    route={item.route}
                    isParent={item.isParent}
                  />
                  {item.isParent && (
                    <svg
                      key={index + "ind"}
                      onClick={() => HandleMobileItemMenu(item)}
                      className="navbar-items-mobile-dropdown__menu-list__arrow"
                      style={item.showSubmenu ? {transform:'rotate(90deg)'} : {}}
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.8"
                      height="7.8"
                      viewBox="0 0 7.8 7.8"
                    >
                      <g fill="none" stroke="currentColor">
                        <path d="m.4 7.4 7-7"></path>
                        <path d="M.4.5h6.9"></path>
                        <path d="M7.3 7.4V.5"></path>
                      </g>
                    </svg>
                  )}
                </li>
              );
            })}
            <li className="navbar-items-mobile-dropdown__menu-list" style={{width:'fit-content'}}>
              <div className="parentItem">
                <button
                  onClick={() => handleChangeLang(t("lang"))}
                  className="navbar-items-mobile-dropdown__menu-list--btn"
                >
                  <span className="navbar-item-drop-down">
                    <LanguageIcon
                      fontSize="small"
                      className="navbar-item--icon"
                    />
                    {t("lang")}
                  </span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Navbar;
