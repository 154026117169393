import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import enLang from "./services/locales/en.json";
import arLang from ".//services/locales/ar.json";
import { register } from 'swiper/element/bundle';
// import TagManager from 'react-gtm-module'
import ReactGA from "react-ga4";

register();
i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: localStorage.getItem('lang') || 'en',        // language to use
    resources: {
        en: {
            translation: enLang               // 'translation' is our custom namespace
        },
        ar: {
            translation: arLang
        },
    },
});

// const tagManagerArgs = {
//     gtmId: 'G-GEB9HDG1F2'
// }

// TagManager.initialize(tagManagerArgs)
ReactGA.initialize("G-GEB9HDG1F2");

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
  </React.StrictMode>
);

