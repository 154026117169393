import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import Navbar from "../components/Navbar";
import "./container.scss"


const Home = React.lazy(() => import('../pages/Home'));
const About = React.lazy(() => import('../pages/About'));
const Services = React.lazy(() => import('../pages/Services'));
const Industries = React.lazy(() => import('../pages/Industries'));
const Insights = React.lazy(() => import('../pages/Insights'));
const Press = React.lazy(() => import('../pages/Press'));
const Footer = React.lazy(() => import('../components/Footer'));
const PressChild = React.lazy(() => import('../pages/PressChild'));
const Contact = React.lazy(() => import('../pages/Contact'));
const ServicesChild = React.lazy(() => import('../pages/ServicesChild'));
const InsightsChild = React.lazy(() => import('../pages/InsightsChild'));


const footerStyle = {
  width : "100%",
  height : "150px" ,
  backgroundColor : "#19272b"
}

const Container = () => {
  return (
    <>
    <Navbar />

    <div className="main-content">
    
    <Routes >
      <Route
        path="/"
        element={
          <Suspense fallback={<></>}>
            <Home />
          </Suspense>
        }
      />
      <Route
        path="/about"
        element={
          <Suspense fallback={<></>}>
            <About />
          </Suspense>
        }
      />
      <Route
        path="/services"
        element={
          <Suspense fallback={<></>}>
            <Services />
          </Suspense>
        }
      />
      <Route
        path="/services/:id"
        element={
          <Suspense fallback={<></>}>
            <ServicesChild />
          </Suspense>
        }
      />
      <Route
        path="/industries"
        element={
          <Suspense fallback={<></>}>
            <Industries />
          </Suspense>
        }
      />
      <Route
        path="/insights"
        element={
          <Suspense fallback={<></>}>
            <Insights />
          </Suspense>
        }
      />
      <Route
        path="/insights/:id"
        element={
          <Suspense fallback={<></>}>
            <InsightsChild />
          </Suspense>
        }
      />
      <Route
        path="/press"
        element={
          <Suspense fallback={<></>}>
            <Press />
          </Suspense>
        }
      />
      <Route
        path="/press/:id"
        element={
          <Suspense fallback={<></>}>
            <PressChild />
          </Suspense>
        }
      />
      <Route
        path="/contact"
        element={
          <Suspense fallback={<></>}>
            <Contact />
          </Suspense>
        }
      />
      <Route element={<h1>Not found!</h1>} />
    </Routes>      
    </div>

    <Footer style={footerStyle}/>
    </>

  );
};

export default Container;
